import React, { useState, useEffect } from 'react';
import './ThreeDotMenu.css';
import { deletePost, getPost } from '../../actions/feedAction';
import { toast } from 'react-toastify';
import { navigate } from 'gatsby';
import { editPost } from '../../actions/feedAction';
import ThreeDotIcon from './three-dots.svg';

const ThreeDotMenu = ({ postid, approve, afterPostAction }) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [isApprove, setIsApprove] = useState(approve === 'approved');
  // console.log('approve in 3', postid);

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleClickOutside = (event) => {
    if (!event.target.closest('.dropdown')) {
      setDropdownVisible(false);
    }
  };
  const handleDelete = async () => {
    setDropdownVisible(false);
    const confirmDelete = window.confirm('Are you sure you want to delete this post?');
    if (!confirmDelete) return; // If user cancels, do nothing
    try {
      const response = await deletePost(postid);
      if (response?.status) {
        toast.success('Post Deleted Successfully');
        afterPostAction();
      } else {
        toast.error(`Failed to delete post, ${response.error_message}`);
      }
    } catch (error) {
      toast.error(`An error occurred while deleting family member, ${error}`);
    }
  };
  const handleEdit = async () => {
    navigate(`/feeds/createFeeds/?postid=${postid}`);
  };
  const handleApprove = async (isApprove) => {
    setDropdownVisible(false);
    try {
      const res = await getPost(postid);
      const postData = {
        ...res,
        status: isApprove ? 'approved' : '',
      };
      const response = await editPost(postid, postData);
      // if (response?.status) {
      toast.success(`Post ${isApprove ? 'Approved' : 'Unapproved'} Successfully`);
      setIsApprove(isApprove);
      afterPostAction();
      // } else {
      //   toast.error(`Failed to ${isApprove ? 'approve' : 'unapprove'} post, ${response.error_message}`);
      // }
    } catch (error) {
      console.error(`Error ${isApprove ? 'approving' : 'unapproving'} post:`, error);
      toast.error(`An error occurred while ${isApprove ? 'approving' : 'unapproving'} the post`);
    }
  };

  // useEffect(() => {
  //   if (postid) fetchPost();
  // }, []);

  // const fetchPost = async () => {
  //   try {
  //     console.log('Fetching post inside');
  //     const res = await getPost(postid?.postid);
  //     setPost(res);
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="header">
      <div className="dropdown">
        <img src={ThreeDotIcon} alt="Three Dots" className="three-dot-icon" onClick={toggleDropdown} />
        {dropdownVisible && (
          <div id="myDropdown" className="dropdown-content show">
            <button onClick={handleDelete}>Delete Post</button>
            <button onClick={handleEdit}>Edit Post</button>
            <button onClick={() => handleApprove(!isApprove)}>{isApprove ? 'Unapprove' : 'Approve Post'}</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ThreeDotMenu;
